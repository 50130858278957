import React from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import BcbsaRouting from "./routing/BcbsaRouting";
import BscRouting from "./routing/BscRouting";
import GeneralRouting from "./routing/GeneralRouting";
import Wizard from "./components/wizard/Wizard";
import "./styles/main.scss";
import AccountRouting from "./routing/AccountRouting";
import FEPRouting from "./routing/FEPRouting";
import AuthWrapper from "./components/shared/AuthWrapper";
import { useContext } from "react";
import { UserContext, USER_FROM } from "./context/UserProvider";
import { ErrorBoundary } from "react-error-boundary";
import ClientRoute from "./components/shared/ClientRoute";
import { Container, Nav } from "react-bootstrap";
import Terms from "./pages/Terms";
import Locations from "./pages/Locations";
import Success from "./pages/Success";
import NavBar from "./components/navigation/NavBar";
import AccountProvider from "./context/AccountProvider";
import Impersonate from "./pages/Impersonate";
import CsrBanner from "./components/shared/CsrBanner";
import { ImpersonationContext } from "./context/ImpersonationProvider";
import NotFound from "./pages/NotFound";
import SomethingWentWrong from "./pages/SomethingWentWrong";
import GoogleAnalytics from "./components/shared/GoogleAnalytics";
import { useAuthKit } from "@authkitcom/react";
import BlueFocusAgreement from "./pages/BlueFocusAgreement";
import AccountRequiredWrapper from "./components/shared/AccountRequiredWrapper";
import ProductBundleProvider from "./context/ProductBundleProvider";
import { ClientConfigContext } from "./context/ClientConfigProvider";
import EligibilityWrapper from "./components/shared/EligibilityWrapper";
import { hotjar } from "react-hotjar";
import Maintenance from "./pages/Maintenance";
import Privacy from "./pages/Privacy";

const App = () => {
  const { user } = useContext(UserContext);
  const { config } = useContext(ClientConfigContext);
  const { isImpersonated, values } = useContext(ImpersonationContext);
  const { authKit } = useAuthKit();
  const userLoggedIn = authKit.isAuthenticated();

  if (config["hotjar.hjid"] !== "" && config["hotjar.hjsv"] !== "") {
    hotjar.initialize(
      parseInt(config["hotjar.hjid"], 10),
      parseInt(config["hotjar.hjsv"], 10)
    );
  }
  // maintenance mode
  if (config["maintenance.enable"] === "true") {
    return <Maintenance />;
  }

  // user is on an invalid client url
  if (
    userLoggedIn &&
    config["site.baseUrl"] !==
      window.location.protocol + "//" + window.location.host
  ) {
    window.location.replace(config["site.baseUrl"] + window.location.pathname);
    return null;
  }

  return (
    <>
      {isImpersonated && (
        <CsrBanner user={values.contactId} agent={values.csrUsername} />
      )}

      <div className={isImpersonated ? "csr-layout" : ""}>
        <AccountProvider>
          <ProductBundleProvider>
            <Router>
              <ClientRoute match="bcbsa">
                <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                  <BcbsaRouting
                    authenticated={user.from === USER_FROM.REDEMPTION}
                  />
                </ErrorBoundary>
              </ClientRoute>
              <ClientRoute match="bsca">
                <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                  <BscRouting />
                </ErrorBoundary>
              </ClientRoute>
              <ClientRoute match="bcbsa-fep" fep>
                <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                  <FEPRouting />
                </ErrorBoundary>
              </ClientRoute>
              <ClientRoute match="fyw">
                <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                  <GeneralRouting />
                </ErrorBoundary>
              </ClientRoute>
              {/* standalone routes */}
              <Switch>
                <Route exact path="/impersonated">
                  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                    <Impersonate />
                  </ErrorBoundary>
                </Route>
                <Route exact path="/privacy-policy">
                  <Container>
                    <div style={{ padding: "24px" }}></div>
                    <Privacy home="/" />
                  </Container>
                </Route>
                <Route exact path="/terms">
                  <Container>
                    <div style={{ padding: "24px" }}></div>
                    <Terms home="/" />
                  </Container>
                </Route>
                <Route exact path="/locations">
                  <EligibilityWrapper>
                    <>
                      <NavBar logo>
                        <Nav
                          className="justify-content-end"
                          style={{ width: "100%" }}
                        >
                          {userLoggedIn ? (
                            <>
                              <Nav.Item>
                                <Nav.Link as={Link} to="/account">
                                  My Account
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link as={Link} to="/account/faqs">
                                  FAQs
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          ) : (
                            <>
                              <Nav.Item>
                                <Nav.Link as={Link} to="/">
                                  Home
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link as={Link} to="/how-it-works">
                                  How It Works
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link as={Link} to="/locations">
                                  Locations
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link as={Link} to="/faqs">
                                  FAQs
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          )}
                        </Nav>
                      </NavBar>
                      <Locations />
                    </>
                  </EligibilityWrapper>
                </Route>
                <Route exact path="/success">
                  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                    <AuthWrapper>
                      <Success />
                    </AuthWrapper>
                  </ErrorBoundary>
                </Route>
                <Route path="/account">
                  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                    <AuthWrapper>
                      <AccountRequiredWrapper>
                        <AccountRouting />
                      </AccountRequiredWrapper>
                    </AuthWrapper>
                  </ErrorBoundary>
                </Route>
                <Route path="/enroll">
                  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                    <Wizard />
                  </ErrorBoundary>
                </Route>
                <Route exact path="/terms">
                  <BlueFocusAgreement />
                </Route>
                {/* Dummy route to not render 404 on all pages contained in client routing */}
                {/* React router does not handle fragments between switch and route */}
                <Route
                  exact
                  path={[
                    "/",
                    "/how-it-works",
                    "faqs",
                    "/sso",
                    "/fep-blue-focus-locations",
                    "/privacy-policy",
                    "/agreement",
                    "/faqs",
                  ]}
                />
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Router>
          </ProductBundleProvider>
        </AccountProvider>
      </div>
      <GoogleAnalytics />
    </>
  );
};

export default App;
